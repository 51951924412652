@import '~@angular/material/theming';
.mat-checkbox-layout {
  white-space: normal !important;
}
@include mat-core();

/* ======== Angular material custom themes ======== */ 
$md-sentrexblue: (
    50 : #e6eaf0,
    100 : #c1cbda,
    200 : #97a9c2,
    300 : #6d86aa,
    400 : #4e6c97,
    500 : #2f5285,
    600 : #2a4b7d,
    700 : #234172,
    800 : #1d3868,
    900 : #122855,
    A100 : #8eafff,
    A200 : #5b8bff,
    A400 : #2867ff,
    A700 : #0e55ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-sentrexgreen: (
    50 : #e0f6f5,
    100 : #b3e9e7,
    200 : #80dad7,
    300 : #4dcbc6,
    400 : #26c0ba,
    500 : #00b5ae,
    600 : #00aea7,
    700 : #00a59d,
    800 : #009d94,
    900 : #008d84,
    A100 : #b9fffa,
    A200 : #86fff6,
    A400 : #53fff2,
    A700 : #3afff0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-sentrexorange: (
    50 : #fdf0e7,
    100 : #fad9c4,
    200 : #f6c09c,
    300 : #f2a674,
    400 : #f09357,
    500 : #ed8039,
    600 : #eb7833,
    700 : #e86d2c,
    800 : #e56324,
    900 : #e05017,
    A100 : #ffffff,
    A200 : #ffe7df,
    A400 : #ffc0ac,
    A700 : #ffad92,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-eisaipink: (
    50 : #f9e1f1,
    100 : #efb4db,
    200 : #e482c4,
    300 : #d950ac,
    400 : #d12b9a,
    500 : #c90588,
    600 : #c30480,
    700 : #bc0475,
    800 : #b5036b,
    900 : #a90158,
    A100 : #ffd3e7,
    A200 : #ffa0cb,
    A400 : #ff6daf,
    A700 : #ff53a2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-retinedirecte_green: (
    50 : #6bc49b,
    100 : #6bc49b,
    200 : #6bc49b,
    300 : #6bc49b,
    400 : #6bc49b,
    500 : #6bc49b,
    600 : #6bc49b,
    700 : #6bc49b,
    800 : #6bc49b,
    900 : #6bc49b,
    A100 : #6bc49b,
    A200 : #6bc49b,
    A400 : #6bc49b,
    A700 : #6bc49b,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-retinedirecte_grey: (
    50 : #84898b,
    100 : #84898b,
    200 : #84898b,
    300 : #84898b,
    400 : #84898b,
    500 : #84898b,
    600 : #84898b,
    700 : #84898b,
    800 : #84898b,
    900 : #84898b,
    A100 : #84898b,
    A200 : #84898b,
    A400 : #84898b,
    A700 : #84898b,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-retinedirecte_lightgrey: (
    50 : #fafafa,
    100 : #b8c1c5,
    200 : #b8c1c5,
    300 : #b8c1c5,
    400 : #b8c1c5,
    500 : #fafafa,
    600 : #b8c1c5,
    700 : #b8c1c5,
    800 : #b8c1c5,
    900 : #b8c1c5,
    A100 : #b8c1c5,
    A200 : #b8c1c5,
    A400 : #b8c1c5,
    A700 : #b8c1c5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

body {
  --primary-color: #43c798;
  --primary-lighter-color: #c7eee0;
  --primary-darker-color: #2cb37b;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #43c798,
  lighter: #c7eee0,
  darker: #2cb37b,
  200: #43c798, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #83898b;
  --accent-lighter-color: #dadcdc;
  --accent-darker-color: #666c6e;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #83898b,
  lighter: #dadcdc,
  darker: #666c6e,
  200: #83898b, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}


$my-app-primary: mat-palette($md-retinedirecte_grey, 500, 900, A100);
$my-app-accent:  mat-palette($md-retinedirecte_green, 500, 900, A100);
// $my-app-primary: mat-palette($mat-primary);
// $my-app-accent:  mat-palette($mat-accent);

$my-app-warn:    mat-palette($mat-red);

$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

$primary: $mat-primary;

$base-theme:Material;
$swatch-name:Material;
$border-radius: 2px;
$primary-palette-name: $my-app-primary;
$secondary-palette-name: $my-app-accent;
$theme-type: light;

.title {
  color: map_get($md-retinedirecte_green, 400)
}

.subheader {
    font-size: 18px;
    color: map_get($md-retinedirecte_green, 400)
}

